<template>
  <div class="columns is-vcentered is-mobile">
    <div class="column is-narrow">
      <span class="icon">
        <i class="fas fa-star has-text-light" />
      </span>
      <span class="has-text-dark">Condition</span>
    </div>
    <div class="column">
      <div class="field is-grouped is-grouped-multiline">
        <p class="control is-expanded has-text-right" v-if="condition">
          <ToggleButton
            @change="submit($event.value)"
            class="is-size-7"
            :color="toggleConfig.colors"
            :labels="toggleConfig.labels"
            :speed="500"
            :value="condition === 1"
            :disabled="$wait.is('loading')"
            :sync="true"
            :width="72"
            :height="28"
            :font-size="12"
            v-if="!integration"
          />
          <span
            class="tag is-medium is-rounded"
            :class="[condition === 1 ? 'is-success' : 'is-danger']"
            v-else
          >
            {{ condition === 1 ? 'Good' : 'Poor' }}
          </span>
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'

import * as companion from 'modules/companion/services'
const $motors = process.env.VUE_APP_TYPE === 'motors'
export default {
  name: 'CompanionCondition',
  data: () => ({ error: null }),
  computed: {
    ...mapGetters({
      condition: 'companion/namaGrade',
      valuationMileage: 'companion/valuationMileage',
      date: 'companion/valuationDate',
      vrm: 'companion/vrm',
      integration: 'auth/isIntegration'
    }),
    toggleConfig() {
      return {
        colors: {
          checked: $motors ? '#177339' : '#5DB000',
          unchecked: $motors ? '#A61B14' : '#EC0E1E',
          disabled: '#b5b5b5'
        },
        labels: {
          checked: 'Good',
          unchecked: 'Poor'
        }
      }
    }
  },
  methods: {
    async submit(value) {
      this.$mxp.track('companion_valuation_actions_toggle_nama_grade')

      try {
        this.$wait.start('loading')

        const { vrm, date, valuationMileage } = this
        const namaGrade = value ? 1 : 4
        const mileageEstimated = valuationMileage.isEstimated
        const options = { forceEstimate: mileageEstimated }
        const mileage = mileageEstimated ? null : valuationMileage.value

        await companion.value({ vrm, mileage, date, namaGrade, options })
      } finally {
        this.$wait.end('loading')
      }
    }
  }
}
</script>

<style lang="sass">
.has-text-dark
  .v-switch-label.v-left
    color: #0F001B !important
</style>
